import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/gcpvn/gcp.vn/src/components/static-layout.js";
import SEO from "../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Chính Sách Bảo Mật" mdxType="SEO" />
    <h1>{`CHÍNH SÁCH BẢO MẬT GCP`}</h1>
    <p>{`Công ty GCP luôn luôn ý thức được rằng hoạt động và sự phát triển của GCP dựa trên sự hợp tác và tin tưởng của Quý khách đối với chúng tôi. Nhằm củng cố và phát triển mối quan hệ này, Công ty GCP sẽ sử dụng những nỗ lực lớn nhất để bảo vệ cho những thông tin mà Quý khách đã cung cấp. Vì vậy, việc lưu trữ và sử dụng các thông tin cá nhân Quý khách đã cung cấp cho GCP sẽ tuân thủ nghiêm ngặt những nguyên tắc sau:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Những thông tin mà Quý khách được yêu cầu cung cấp là những thông tin có liên quan và cần thiết để GCP hiểu rõ nhu cầu của Quý khách đồng thời đảm bảo cho việc quản lý, khai thác, sử dụng website được an toàn và thuận tiện;`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Việc lưu trữ và sử dụng thông tin cá nhân của Quý khách sẽ được thực hiện một cách hệ thống, chính xác và cập nhật để đảm bảo tối đa lợi ích của khách hàng khi khai thác và sử dụng dịch vụ và sản phẩm của GCP;`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`GCP cam kết không tiết lộ các thông tin cá nhân của Quý khách cho bất kỳ cá nhân, tổ chức nào khác trừ khi được sự đồng ý của Quý khách hoặc theo quy định pháp luật;`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`GCP duy trì hệ thống bảo vệ nghiệm ngặt để lưu trữ và bảo vệ thông tin của Quý khách khỏi bất kỳ sự truy cập trái phép nào;`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Việc truy cập thông tin cá nhân của Quý khách và các nhân viên của GCP sẽ tuân thủ các quy định về bảo mật của GCP.`}</p>
      </li>
    </ol>
    <p><em parentName="p">{`Chính sách bảo mật có giá trị từ ngày 18 tháng 01 năm 2017.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      